import React from 'react'
import { withPrefix } from 'gatsby'

const ShareAction = () => (
  <div className="row">
    <div className="small-12 columns">
      <div className="share-action">
        <h3 class="sub-heading bold" style={{ marginBottom: '24px' }}>
          {/* Want to see a particular MP at the top of the list? Share this page with your friends
          today. */}
          Want to let people know who topped the list? Share this page with your friends today.
        </h3>
        <ul class="share-buttons">
          <li>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=hardright.getup.org.au"
              title="Share on Facebook"
              target="_blank"
              className="btn btn-grey"
            >
              <img alt="Send email" src={withPrefix('/img/facebook.svg')} />
              Share on Facebook
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/intent/tweet?source=http%3A%2F%2Fwww.hardright.getup.org.au&text=Who%20do%20you%20want%20to%20kick%20out%3F:%20http%3A%2F%2Fwww.hardright.getup.org.au"
              target="_blank"
              title="Tweet"
              className="btn btn-grey"
            >
              <img alt="Send email" src={withPrefix('/img/twitter.svg')} />
              Tweet about this page
            </a>
          </li>
          <li>
            <a
              href="mailto:?subject=Who%20do%20you%20want%20to%20kick%20out%3F&body=GetUp%20is%20gearing%20up%20to%20take%20on%20hard%20right%20MPs%20in%20the%20upcoming%20federal%20election.%20And%20we%20need%20your%20help%20deciding%20who%20to%20target.:%20http%3A%2F%2Fwww.hardright.getup.org.au"
              target="_blank"
              title="Send email"
              className="btn btn-grey"
            >
              <img alt="Send email" src={withPrefix('/img/email.svg')} />
              Send via email
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default ShareAction
