import React from 'react'

const Notification = props => (
  <div className="row">
    <div className="small-12 columns">
      <div className="notification-container">
        <p>{props.text}</p>
      </div>
    </div>
  </div>
)

export default Notification
