import React, { Component } from 'react'
import Layout from '../layout/Layout.jsx'
import Header from '../components/Header'
import MpList from '../data/MPList'
import '../styles/chart.scss'
import Chart from '../components/Chart.jsx'
import LoadingChart from '../components/LoadingChart.jsx'
import ShareAction from '../components/ShareAction.jsx'
import Notification from '../components/Notification.jsx'
import DonateButton from '../components/DonateButton.jsx'
import UpdatedResult from '../components/UpdatedResult.jsx'

let sortedLeaderboard = []

class Leaderboard extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      mps: MpList,
      leaderboard: {
        peter_dutton: 0,
        tony_abbott: 0,
        kevin_andrews: 0,
        scott_morrison: 0,
        christian_porter: 0,
        nicolle_flint: 0,
        steve_ciobo: 0,
        alan_tudge: 0,
        lucy_wicks: 0,
        josh_frydenberg: 0,
        andrew_hastie: 0,
        george_christensen: 0,
        angus_taylor: 0,
        rowan_ramsey: 0,
        michael_sukkar: 0,
      },
      width: 0,
      isLoading: false,
      donateLink:
        'https://www.getup.org.au/campaigns/federal-election-2019/vote-them-out-donation/vote-them-out-b0bfb29b-0f9c-4061-a85d-091a3a2db62f',
      recurring: false,
    }
  }

  sortProperties = obj => {
    // convert object into array
    var sortable = []
    for (var key in obj) if (obj.hasOwnProperty(key)) sortable.push([key, obj[key]]) // each item is an array in format [key, value]

    // sort items by value
    sortable.sort(function(a, b) {
      return b[1] - a[1] // compare numbers
    })
    return sortable // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    const url = 'https://www.getup.org.au/api/vote_them_out_scores?cm_id=48793'
    fetch(url)
      .then(res => {
        return res.json()
      })
      .then(data => {
        this.setState({ leaderboard: { ...this.state.leaderboard, ...data } })
        this.setState({ isLoading: false })
      })

    // for donation
    function getURLParam(sParam) {
      var sPageURL = window.location.search.substring(1)
      var sURLVariables = sPageURL.split('&')
      var keyAndValue, indexOfEquals, key, value
      for (var i = 0; i < sURLVariables.length; i++) {
        keyAndValue = sURLVariables[i]
        indexOfEquals = keyAndValue.indexOf('=')
        if (indexOfEquals > -1) {
          key = keyAndValue.substring(0, indexOfEquals)
          value = keyAndValue.substring(indexOfEquals + 1).replace(/\//g, '')
          if (key === sParam) {
            return value
          }
        }
      }
    }

    if (getURLParam('s')) {
      this.setState({ recurring: true })
    }
  }

  render() {
    sortedLeaderboard = this.sortProperties(this.state.leaderboard)
    const maxVote = sortedLeaderboard[0][1]
    const mps = this.state.mps
    const totalVotes = sortedLeaderboard.map(i => i[1]).reduce((a, b) => a + b)

    const chartProps = {
      mps,
      leaderboard: sortedLeaderboard,
      totalVotes,
      maxVote,
    }

    const isRecurring = this.state.recurring ? 'make a recurring donation' : 'chip in'

    return (
      <Layout>
        {this.props.location.state &&
          this.props.location.state.isFormSubmitted && (
            <Notification text="Thanks for the submission" />
          )}
        <Header header={header} />
        {/* <div className="row" style={{ marginTop: '-32px', marginBottom: '54px' }}>
          <div className="small-12 columns">
            <p className="sub-heading bold" style={{ marginBottom: '16px' }}>
              But can't make it happen without your help. Can you {isRecurring} to fund GetUp's
              election effort to take on the hard right?{' '}
            </p>
            <DonateButton buttonText="Chip in now!" large />
          </div>
        </div> */}
        <UpdatedResult />
        {/* <div className="row">
          <div className="small-12 columns" style={{ marginTop: '2rem' }}>
            {!this.state.isLoading ? (
              <Chart {...chartProps} />
            ) : (
              <LoadingChart mps={this.state.leaderboard} />
            )}
          </div>
        </div> */}
        <ShareAction />
      </Layout>
    )
  }
}

const header = {
  title: 'Where they stand',
  subtitle: [
    'A massive 28,761 of you voted — along with data from other sources, this direction will help determine which hard right MPs GetUp targets in the upcoming federal election.',
  ],
}

export default Leaderboard
