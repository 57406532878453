import React, { Component } from 'react'
import { withPrefix } from 'gatsby'
import { snakeCase } from 'lodash'

export default class UpdatedResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mpList: [
        {
          stateName: 'New South Wales',
          mps: [
            {
              name: 'Tony Abbott',
              votes: '15,932',
            },

            {
              name: 'Barnaby Joyce',
              votes: '5,065',
            },

            {
              name: 'Scott Morrison',
              votes: '3,835',
            },

            {
              name: 'Craig Kelly',
              votes: '3,190',
            },

            {
              name: 'Angus Taylor',
              votes: '1,914',
            },
          ],
        },
        {
          stateName: 'Victoria',
          mps: [
            {
              name: 'Kevin Andrews',
              votes: '3,600',
            },
            {
              name: 'Greg Hunt',
              votes: '2,312',
            },
            {
              name: 'Josh Frydenberg',
              votes: '1,851',
            },

            {
              name: 'Michael Sukkar',
              votes: '1,254',
            },
            {
              name: 'Alan Tudge',
              votes: '1,049',
            },
          ],
        },
        {
          stateName: 'Queensland',
          mps: [
            {
              name: 'Peter Dutton',
              votes: '22,028',
            },
            {
              name: 'George Christensen',
              votes: '6,090',
            },
          ],
        },
        {
          stateName: 'South Australia',
          mps: [
            {
              name: 'Nicolle Flint',
              votes: '932',
            },
            {
              name: 'Rowan Ramsey',
              votes: '444',
            },
          ],
        },
        {
          stateName: 'Western Australia',
          mps: [
            {
              name: 'Christian Porter',
              votes: '4,458',
            },
            {
              name: 'Andrew Hastie',
              votes: '1,389',
            },
          ],
        },
      ],
    }
  }
  render() {
    return (
      <div class="row">
        {this.state.mpList.map(state => {
          return (
            <div className="small-12 medium-6 large-4 columns">
              <div className="updated-result--container">
                <h2 className="h5 state-name">{state.stateName}</h2>
                {state.mps.map(mp => (
                  <div className="mp-container">
                    <span>
                      <h3 className="mp-name h5">{mp.name}</h3>
                      <div className="mp-votes">{mp.votes}</div>
                    </span>
                    <img src={withPrefix('/img/' + snakeCase(mp.name) + '.png')} />
                  </div>
                ))}
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
